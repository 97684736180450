import { useAuth0 } from "@auth0/auth0-react";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import { deleteAccount } from "apis/consumer";
import blurryGradientBg from "assets/blurry-gradient-bg.svg";
import { auth0Config } from "auth0config";
import { Button, ButtonVariant } from "components/button";
import LoginButton from "components/loginButton";
import { useState } from "react";

const faqs = [
  {
    question: "What happens to my data when I delete my account?",
    answer:
      "When you delete your account, we delete all of your personal data from our systems. This includes your name, email address, and any other information you provided to authenticate with us. We will only retain data that we are legally required to keep, such as for tax and security purposes.",
  },
  {
    question: "How long does it take to delete my account?",
    answer: "It typically takes 5-10 business days to process your account deletion request.",
  },
  {
    question: "Will I receive a refund for my account deletion?",
    answer: "No, we do not offer refunds for account deletions. Once your account is deleted, it cannot be restored.",
  },
  {
    question: "Can I cancel my account deletion request?",
    answer:
      "Once you submit your account deletion request, it cannot be canceled. Please ensure you want to proceed with deleting your account before submitting the request.",
  },
];

const Faqs = () => {
  return (
    <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
      <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
        <h2 className="text-2xl font-semibold tracking-tight text-gray-900 sm:text-3xl">Frequently asked questions</h2>
        <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
          {faqs.map((faq) => (
            <Disclosure key={faq.question} as="div" className="pt-6">
              <dt>
                <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                  <span className="text-base/7 font-semibold">{faq.question}</span>
                  <span className="ml-6 flex h-7 items-center">
                    <PlusSmallIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                    <MinusSmallIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden" />
                  </span>
                </DisclosureButton>
              </dt>
              <DisclosurePanel as="dd" className="mt-2 pr-12">
                <p className="text-base/7 text-gray-600">{faq.answer}</p>
              </DisclosurePanel>
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  );
};

const DeleteForm = () => {
  const { isLoading, error, user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [result, setResult] = useState<string | null>(null);

  const handleSubmit = async () => {
    if (!user) {
      return;
    }

    setIsSubmitting(true);
    setResult("Submitting...");

    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: auth0Config.audience,
        scope: auth0Config.scope,
      },
    });
    const userId = user!.sub;

    deleteAccount(userId, token)
      .then((res) => {
        setResult("Account deletion request submitted successfully.");
      })
      .catch((err) => {
        setResult(err.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  if (isLoading) {
    return <div className="mx-auto mt-4 max-w-xl text-xl text-gray-600">Loading...</div>;
  }

  if (error) {
    return <div className="mx-auto mt-4 max-w-xl text-xl text-gray-600">Oops... {error.message}</div>;
  }

  if (!isAuthenticated) {
    return (
      <>
        <p className="mx-auto mt-4 max-w-xl text-xl text-gray-600">
          We're sorry to see you go. If you'd like to delete your account, please login to submit your account deletion
          request.
        </p>
        <LoginButton prompt="Log In" redirectUri={window.location.href} />
      </>
    );
  }

  if (isSubmitting) {
    return <div className="mx-auto mt-4 max-w-xl text-xl text-gray-600">{result ?? "Submitting..."}</div>;
  }

  return (
    <>
      <p className="mx-auto mt-4 max-w-xl text-xl text-gray-600">
        Please click the button below to submit your account deletion request.{" "}
        <span className="text-red-500 font-bold">Note: This cannot be undone.</span>
      </p>
      <Button variant={ButtonVariant.DANGER} onClick={handleSubmit}>
        Delete My Account
      </Button>
    </>
  );
};

export const DeleteAccount = () => {
  return (
    <div className="bg-white">
      <div className="relative overflow-hidden">
        {/* Decorative background image and gradient */}
        <div aria-hidden="true" className="absolute inset-0">
          <div className="absolute inset-0 mx-auto overflow-hidden">
            <img alt="" src={blurryGradientBg} className="h-full w-full object-cover object-center" />
          </div>
          <div className="absolute inset-0 bg-white bg-opacity-75" />
          <div className="absolute inset-0 bg-gradient-to-t from-white via-white" />
        </div>

        {/* Callout */}
        <section
          aria-labelledby="call-to-action-heading"
          className="relative mx-auto flex max-w-7xl flex-col items-center px-4 pt-32 text-center sm:px-6 lg:px-8"
        >
          <div className="mx-auto max-w-2xl lg:max-w-none">
            <h2
              id="call-to-action-heading"
              className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl"
            >
              Delete your Ekomoto account
            </h2>
            <DeleteForm />
          </div>
        </section>
      </div>
      <Faqs />
    </div>
  );
};
