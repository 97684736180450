import React from "react";

export enum ButtonVariant {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  DANGER = "danger",
}

type ButtonColor = {
  [key in ButtonVariant]: string;
};

const buttonColors: ButtonColor = {
  [ButtonVariant.PRIMARY]: "bg-primary",
  [ButtonVariant.SECONDARY]: "bg-secondary",
  [ButtonVariant.DANGER]: "bg-red-500",
};

export const Button = ({
  children,
  onClick,
  variant = ButtonVariant.PRIMARY,
}: {
  children: React.ReactNode;
  onClick: () => void;
  variant: ButtonVariant;
}) => {
  const buttonColor = buttonColors[variant];
  return (
    <button
      className={`mt-6 inline-flex items-center justify-center w-full rounded-full border border-transparent px-8 py-3 font-medium text-white hover:brightness-110 sm:w-auto transition-all ${buttonColor}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
