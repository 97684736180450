const baseURL = process.env.REACT_APP_SWAP_API_URL;

export const deleteAccount = async (userId: string, token: string): Promise<boolean> => {
  const response = await fetch(`${baseURL}/v1/identity/user/${userId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });

  if (!response.ok) {
    const json = await response.json();
    throw new Error(`Failed to delete account: ${json.message}`);
  }

  return true;
};
