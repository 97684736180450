import { useAuth0 } from "@auth0/auth0-react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import React from "react";

function LoginButton({ prompt, redirectUri }: { prompt?: string; redirectUri?: string }) {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      className="mt-6 inline-flex items-center justify-center w-full rounded-full border border-transparent bg-primary px-8 py-3 font-medium text-white hover:brightness-110 sm:w-auto transition-all"
      onClick={() =>
        loginWithRedirect({
          authorizationParams: {
            redirect_uri: redirectUri ?? window.location.origin,
          },
        })
      }
    >
      {prompt ?? "Log in"}
      <ArrowRightIcon className="ml-2 h-5 w-5" />
    </button>
  );
}

export default LoginButton;
